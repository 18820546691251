<template>
  <div class="party-header" :style="{'background': `url(${party.game.picture_url})`}">
    <div class="party-header__game-title">{{ party.game.label }}</div>
    <div class="party-header__party-title">{{ party.label }}</div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  computed: {
    ...mapGetters({
      party: 'getParty',
    }),
  },
};
</script>

<style lang="scss">
.party-header {
  width: 300px;
  height: 200px;
  margin: 0 auto;
  background-size: cover !important;
  border-radius: 20px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: stretch;

  &__game-title {
    font-size: 35px;
    font-weight: bold;
    text-transform: uppercase;
    margin-bottom: 30px;
  }

  &__party-title {
    font-size: 25px;
    font-weight: lighter;
  }
}
</style>
