<template>
  <div class="cta" @click="clickHandler">
    <i
      v-if="icon"
      class="cta__icon"
      :class="processing ? 'fas fa-spin fa-spinner' : icon"
    ></i>
    {{ label }}
  </div>
</template>

<script>
export default {
  props: {
    label: {
      type: String,
      default: null,
    },
    icon: {
      type: String,
      default: null,
    },
    processing: {
      type: Boolean,
      default: false,
    },
  },

  methods: {
    clickHandler() {
      this.$emit('click');
    },
  },
};
</script>

<style lang="scss">
.cta {
  display: inline-flex;
  cursor: pointer;
  background: #56b545;
  border: 1px solid #56b545;
  color: #ffffff;
  padding: 15px 30px;
  border-radius: 30px;
  transition: all .3s;
  text-transform: uppercase;

  &__icon {
    margin-right: 7px;
  }

  &:hover {
    background: #ffffff;
    color: #56b545;
  }
}
</style>
