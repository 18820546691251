<template>
  <div class="input-field" :class="{'has-error': v.$error}">
    <label v-if="label">
      {{ label }}
    </label>
    <input
      :type="type"
      :placeholder="label"
      @input="update"
      @keypress.enter="enter"
    />
    <template v-if="v.$error">
      <div
        v-if="hasError('required')"
        class="input-field__error"
      >This field is required</div>
      <div
        v-if="hasError('email')"
        class="input-field__error"
      >This is not a valid email address</div>
    </template>
  </div>
</template>

<script>
export default {
  props: {
    type: {
      type: String,
      default: 'text',
    },
    value: {
      type: [String, Number],
      default: null,
    },
    label: {
      type: String,
      default: null,
    },
    v: {
      type: Object,
      default: () => {},
    },
  },

  methods: {
    hasError(field) {
      return this.v[field] !== undefined && !this.v[field];
    },
    update(e) {
      this.$emit('input', e.target.value);
    },
    enter() {
      this.$emit('confirmed');
    },
  },
};
</script>

<style lang="scss">
.input-field {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  &__error, label {
    line-height: 22px;
    font-size: 12px;
    margin-left: 15px;
  }

  &__error {
    color: #b55050;
  }

  label {
    font-weight: 600;
  }

  input {
    background: #f1f1f1;
    width: 100%;
    padding: 10px 15px;
    border-radius: 15px;
    border: 1px solid #E6E6E8;
  }

  &.has-error {
    label {
      color: #b55050;
    }

    input {
      border: 1px solid #b55050;
      color: #b55050;
    }
  }
}
</style>
