<template>
  <div class="party" v-if="party">
    <PartyHeader />
    <div class="party__form">
      <InputField
        class="party__form--input"
        :label="$t('form.name')"
        type="text"
        v-model="form.name"
        :v="$v.form.name"
        @confirmed="process"
      />
      <InputField
        class="party__form--input"
        :label="$t('form.email')"
        type="email"
        v-model="form.email"
        :v="$v.form.email"
        @confirmed="process"
      />
      <Cta
        icon="fas fa-check"
        :label="$t('form.confirm')"
        :processing="processing"
        @click="process"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { required, email } from 'vuelidate/dist/validators.min';
import PartyHeader from '../components/PartyHeader.vue';
import InputField from '../components/InputField.vue';
import Cta from '../components/Cta.vue';

export default {
  components: { Cta, PartyHeader, InputField },

  data() {
    return {
      processing: false,
      form: {
        name: null,
        email: null,
      },
    };
  },

  computed: {
    ...mapGetters({
      party: 'getParty',
    }),
  },

  methods: {
    process() {
      this.$v.$touch();
      if (!this.$v.$error && !this.processing) {
        this.processing = true;
        const { name, email } = this.form;
        const payload = { name, email, party_id: this.party.id };
        this.$store.dispatch('createSignup', payload).then((response) => {
          console.log('success');
          this.$router.push({
            name: 'Schedule',
            params: {
              party: this.$route.params.party,
              signup: response.data.data.uuid,
            },
          });
        }).catch((error) => console.log('Error creating signup', error)).finally(() => {
          this.processing = false;
        });
      }
    },
  },

  mounted() {
    this.$store.commit('setParty', null);
    this.$store.dispatch('fetchParty', this.$route.params.party).then(() => {
      this.$i18n.locale = this.party.language;
    });
  },

  validations: {
    form: {
      name: { required },
      email: { required, email },
    },
  },
};
</script>

<style lang="scss">
.party {
  &__form {
    max-width: 600px;
    margin: 0 auto;

    &--input {
      margin: 20px 0;
    }
  }
}
</style>
